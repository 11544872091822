// Angular
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
// Layout
import { OffcanvasOptions } from '../../../../core/_base/layout';
import { AuthService } from 'src/app/core/auth';
@Component({
  selector: 'kt-quick-user-panel',
  templateUrl: './quick-user-panel.component.html',
  styleUrls: ['./quick-user-panel.component.scss']
})
export class QuickUserPanelComponent implements OnInit {
  user$: Observable<any>;
  // Public properties
  offcanvasOptions: OffcanvasOptions = {
    overlay: true,
    baseClass: 'offcanvas',
    placement: 'right',
    closeBy: 'kt_quick_user_close',
    toggleBy: 'kt_quick_user_toggle'
  };
  constructor(private authService: AuthService) {
  }
  /**
   * On init
   */
  ngOnInit(): void {
    this.user$ = this.authService.getCurrentUser();
  }
  /**
   * Log out
   */
  logout() {
    this.authService.logout();
  }
}
