import { AuthService } from '../auth';
export class MenuConfig {
  constructor(private authService: AuthService) { }

  public menuAdministradorGral: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/administracion-general/dashboard',
          //translate: 'MENU.DASHBOARD',
          bullet: 'dot',
        },
        // {
        //   title: 'Mi Perfil',
        //   root: true,
        //   icon: 'flaticon2-user',
        //   page: '/mi-perfil'
        // },
        { section: 'Administración General' },
        {
          title: 'Zonas',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Zonas de envío',
              page: '/administracion-general/zonas-envios/listado'
            },
            {
              title: 'Localidades',
              page: '/administracion-general/zonas-envios/localidades'
            },
            {
              title: 'Colonias',
              page: '/administracion-general/zonas-envios/colonias'
            },
          ]
        },
        {
          title: 'Empresas',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Listado de empresas',
              page: '/administracion-general/empresas/listado'
            },
            {
              title: 'Crear empresa',
              page: '/administracion-general/empresas/crear'
            },
          ]
        },
      ]
    },
    aside: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/administracion-general/dashboard',
          //translate: 'MENU.DASHBOARD',
          bullet: 'dot',
        },
        // {
        //   title: 'Mi Perfil',
        //   root: true,
        //   icon: 'flaticon2-user',
        //   page: '/mi-perfil'
        // },
        { section: 'Administración General' },
        {
          title: 'Zonas',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Zonas de envío',
              page: '/administracion-general/zonas-envios/listado'
            },
            {
              title: 'Localidades',
              page: '/administracion-general/zonas-envios/localidades'
            },
            {
              title: 'Colonias',
              page: '/administracion-general/zonas-envios/colonias'
            },
          ]
        },
        {
          title: 'Empresas',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Listado de empresas',
              page: '/administracion-general/empresas/listado'
            },
            {
              title: 'Crear empresa',
              page: '/administracion-general/empresas/crear'
            },
          ]
        },
      ]
    },
  };

  public menuAdministradorTienda: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/administracion-tienda/dashboard',
          //translate: 'MENU.DASHBOARD',
          bullet: 'dot',
        },
        { section: 'Administración de Tiendas' },
        {
          title: 'Sucursales',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Listado de sucursales',
              page: '/administracion-tienda/sucursales/listado'
            },
            {
              title: 'Agregar sucursales',
              page: '/administracion-tienda/sucursales/crear'
            },
          ],
        },
        {
          title: 'Categorías',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Listado de categorías',
              page: '/administracion-tienda/categorias/listado'
            },
            {
              title: 'Agregar categoría',
              page: '/administracion-tienda/categorias/crear'
            }
          ]
        },
        {
          title: 'Combos',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Listado de combos',
              page: '/administracion-tienda/combos/listado'
            },
            {
              title: 'Agregar combo',
              page: '/administracion-tienda/combos/crear'
            }
          ]
        },
        {
          title: 'Productos',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Listado de productos',
              page: '/administracion-tienda/productos/listado'
            },
            {
              title: 'Agregar producto',
              page: '/administracion-tienda/productos/crear'
            }
          ]
        },
        {
          title: 'Extras',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Listado de Extras ingredientes',
              page: '/administracion-tienda/extras-ingredientes/listado'
            },
            {
              title: 'Agregar Extra Ingrediente',
              page: '/administracion-tienda/extras-ingredientes/crear'
            }
          ]
        },
        {
          title: 'Zonas',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Zonas de envío',
              page: '/administracion-tienda/zonas-envio/listado'
            },
            {
              title: 'Localidades',
              page: '/administracion-tienda/localidades/listado'
            },
            {
              title: 'Colonias',
              page: '/administracion-tienda/colonias'
            }
          ]
        }
      ]
    },
    aside: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/administracion-tienda/dashboard',
          //translate: 'MENU.DASHBOARD',
          bullet: 'dot',
        },
        // {
        //   title: 'Mi Perfil',
        //   root: true,
        //   icon: 'flaticon2-user',
        //   page: '/mi-perfil'
        // },
        { section: 'Administración de Tiendas' },
        {
          title: 'Sucursales',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Listado de sucursales',
              page: '/administracion-tienda/sucursales/listado'
            },
            {
              title: 'Agregar sucursales',
              page: '/administracion-tienda/sucursales/crear'
            },
          ],
        },
        {
          title: 'Categorías',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Listado de categorías',
              page: '/administracion-tienda/categorias/listado'
            },
            {
              title: 'Agregar categoría',
              page: '/administracion-tienda/categorias/crear'
            }
          ]
        },
        {
          title: 'Combos',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Listado de combos',
              page: '/administracion-tienda/combos/listado'
            },
            {
              title: 'Agregar combo',
              page: '/administracion-tienda/combos/crear'
            }
          ]
        },
        {
          title: 'Productos',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Listado de productos',
              page: '/administracion-tienda/productos/listado'
            },
            {
              title: 'Agregar producto',
              page: '/administracion-tienda/productos/crear'
            }
          ]
        },
        {
          title: 'Extras',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Listado de Extras ingredientes',
              page: '/administracion-tienda/extras-ingredientes/listado'
            },
            {
              title: 'Agregar Extra Ingrediente',
              page: '/administracion-tienda/extras-ingredientes/crear'
            }
          ]
        },
        {
          title: 'Zonas',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Zonas de envío',
              page: '/administracion-tienda/zonas-envio/listado'
            },
            {
              title: 'Localidades',
              page: '/administracion-tienda/localidades/listado'
            },
            {
              title: 'Colonias',
              page: '/administracion-tienda/colonias'
            }
          ]
        }
      ]
    },
  };

  public menuAdministradorSucursal: any = {
    header: {
      self: {},
      items: [
        { section: 'Administración de Sucursal' },
        {
          title: 'Pedidos',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Mis Pedidos',
              page: '/administracion-sucursal/pedidos'
            },
            {
              title: 'Historial de pedidos',
              page: '/administracion-sucursal/pedidos/historial-pedidos'
            },
          ],
        },
        // {
        //   title: 'Repartidores',
        //   bullet: 'dot',
        //   icon: 'flaticon2-list-2',
        //   root: true,
        //   submenu: [
        //     {
        //       title: 'Repartidores',
        //       page: '/administracion-sucursal/repartidores/listado'
        //     },
        //     {
        //       title: 'Agregar repartidor',
        //       page: '/administracion-sucursal/repartidores/crear'
        //     },
        //   ],
        // },
      ]
    },
    aside: {
      self: {},
      items: [
        { section: 'Administración de Sucursal' },
        {
          title: 'Pedidos',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          submenu: [
            {
              title: 'Mis Pedidos',
              page: '/administracion-sucursal/pedidos'
            },
            {
              title: 'Historial de pedidos',
              page: '/administracion-sucursal/pedidos/historial-pedidos'
            },
          ],
        },
        // {
        //   title: 'Repartidores',
        //   bullet: 'dot',
        //   icon: 'flaticon2-list-2',
        //   root: true,
        //   submenu: [
        //     {
        //       title: 'Repartidores',
        //       page: '/administracion-sucursal/repartidores/listado'
        //     },
        //     {
        //       title: 'Agregar repartidor',
        //       page: '/administracion-sucursal/repartidores/crear'
        //     },
        //   ],
        // },
      ]
    },
  };

  public get configs(): any {
    let menu;
    if (this.authService.getCurrentUser().rol == 'Administrador General') {
      menu = this.menuAdministradorGral;
    } else if (this.authService.getCurrentUser().rol == 'Administrador Tienda') {
      menu = this.menuAdministradorTienda;
    } else if (this.authService.getCurrentUser().rol == 'Administrador de Sucursal') {
      menu = this.menuAdministradorSucursal;
    }
    menu.header.items.push(
      {
        title: 'Cerrar sesión',
        root: true,
        page: '/auth/login',
      }
    );
    menu.aside.items.push(
      {
        title: 'Cerrar sesión',
        root: true,
        page: '/auth/login',
      }
    );
    return menu;
  }
}
