// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../_services';
@Injectable()
export class AuthAdministradorGeneralGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLogged = this.authService.isLoggedIn();
    const user = this.authService.getCurrentUser();
    if (isLogged == false || (user && user.rol != 'Administrador General')) {
      this.router.navigateByUrl('/auth/login');
      return false;
    } else {
      return true;
    }
  }
}
