<!--begin::Body-->
<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0 height-100">
	<!--begin::Signin-->
	<div class="login-form login-signin">
		<div class="text-center mb-10 mb-lg-20">
			<h3 class="font-size-h1">¿Contraseña olvidada? </h3>
			<p class="text-muted font-weight-semi-bold">Ingresa tu email para recuperarla</p>
		</div>
		<kt-auth-notice></kt-auth-notice>
		<form class="form" [formGroup]="forgotPasswordForm" autocomplete="off">
			<div class="form-group">
				<mat-form-field style="border: 1px solid #1E1E2D;padding: 5px 15px;border-radius: 10px;">
					<mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
					<input matInput type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
						formControlName="email" autocomplete="off" />
					<mat-error *ngIf="isControlHasError('email','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','email')">
						<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','minLength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','maxLength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<!--begin::Action-->
			<div class="fform-group d-flex flex-wrap flex-center">
				<button (click)="recoverPassword()" id="kt_login_forgot_submit"
					class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
					[ngClass]="{'spinner spinner-right spinner-md spinner-light': loading}">Enviar</button>
				<a href="/auth/login" id="kt_login_signup_cancel"
					class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">Atras</a>
			</div>
			<!--end::Action-->
		</form>
	</div>
</div>