import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'kt-error4',
  templateUrl: './error4.component.html',
  styleUrls: ['./../../../../../assets/sass/pages/error/error-4.scss']
})
export class Error4Component implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}
