// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth';
@Component({
  selector: 'kt-user-profile4',
  templateUrl: './user-profile4.component.html',
})
export class UserProfile4Component implements OnInit {
  // Public properties
  user: any;
  @Input() userDropdownStyle = 'light';
  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;
  inicialesUsuario: string;
  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   */
  constructor(private authService: AuthService) {
  }
  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */
  /**
   * On init
   */
  showFirstWords() {
    this.inicialesUsuario = '';
    this.user?.nombre.split(' ').forEach((element, index) => {
      if (index < 2) {
        this.inicialesUsuario = this.inicialesUsuario.concat(element.substring(0, 1).toUpperCase());
      }
    });
  }
  ngOnInit(): void {
    this.user = this.authService.getCurrentUser();
    this.showFirstWords();
  }
  /**
   * Log out
   */
  logout() {
    //Cerrar sesión
  }
}
