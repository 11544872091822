// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
// Auth
import { AuthAdministradorGeneralGuard, AuthAdminTiendaGuard, AuthAdminSucursalGuard } from './core/auth';
import { Error4Component } from './views/pages/error/error4/error4.component';
import { FormEmpresaComponent } from './views/pages/apps/admonGeneral/empresas/form-empresa/form-empresa.component';
import { VersionInfoComponent } from './views/pages/apps/utils/version-info/version-info.component';

const routes: Routes = [
  {
    path: 'registro',
    component: FormEmpresaComponent
  },
  {
    path: 'version-history',
    component: VersionInfoComponent
  },
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    children: [

      {
        path: 'administracion-general',
        canActivate: [AuthAdministradorGeneralGuard],
        loadChildren: () => import('./views/pages/apps/admonGeneral/admon-general.module').then(m => m.AdmonGeneralModule),
      },

      {
        path: 'administracion-tienda',
        canActivate: [AuthAdminTiendaGuard],
        loadChildren: () => import('./views/pages/apps/admon-tienda/admon-tienda.module').then(m => m.AdmonTiendaModule),
      },

      {
        path: 'administracion-sucursal',
        canActivate: [AuthAdminSucursalGuard],
        loadChildren: () => import('./views/pages/apps/admon-sucursal/admon-sucursal.module').then(m => m.AdmonSucursalModule),
      },

      { path: '', redirectTo: 'administracion-general', pathMatch: 'full' },
      { path: '**', component: Error4Component },
    ],
  },
  { path: '**', component: Error4Component },
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
