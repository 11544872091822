import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  jwt = new JwtHelperService();
  token: any;
  idSucursal:number;
  tienda: any;
  constructor(private http: HttpClient, private router: Router) {
  }
  // Authentication/Authorization
  login(correo: string, contrasena: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'adminLogin', { "correo": correo, "contrasena": contrasena });
  }
  isLoggedIn() {
    /*const isLogged = new BehaviorSubject<any>(false);
    return isLogged;*/
    /*if (this.jwt.getTokenExpirationDate(environment.authTokenKey) == null) {
      return false;
    } else {
      return !this.jwt.isTokenExpired(environment.authTokenKey);
    }*/
    this.token = localStorage.getItem(environment.authTokenKey);
    this.token = this.jwt.decodeToken(this.token);
    if (this.token != null) {
      if (this.token["uid"] != null) {
        this.idSucursal = this.token.idSucursal;
        this.tienda = JSON.parse(localStorage.getItem('tiendaActual'));
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  // getUserByToken(): Observable<any> {
  //   const userToken = localStorage.getItem(environment.authTokenKey);
  //   let httpHeaders = new HttpHeaders();
  //   httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + userToken);
  //   return this.http.get<any>(API_USERS_URL, { headers: httpHeaders });
  // }
  getCurrentUser() {
    this.token = localStorage.getItem(environment.authTokenKey);
    let currentUser = this.jwt.decodeToken(this.token);
    return currentUser;
  }
  register(user: any): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(environment.apiUrl + '/?u=createUser', user, { headers: httpHeaders })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return null;
        })
      );
  }
  async logout() {
    localStorage.removeItem(environment.authTokenKey);
    localStorage.removeItem("tiendaActual");
    this.router.navigateByUrl('/auth/login');
  }
  /*
   * Submit forgot password request
   *
   * @param {string} email
   * @returns {Observable<any>}
   */
  public requestPassword(email: string): Observable<any> {
    return this.http.get(environment.apiUrl + 'requestPassword' + '/forgot?=' + email)
      .pipe(catchError(this.handleError('forgot-password', []))
      );
  }
  /*
   * Handle Http operation that failed.
   * Let the app continue.
    *
  * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
  get currentToken() {
    return localStorage.getItem(environment.authTokenKey);
  }
}
