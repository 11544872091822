<div class="d-flex flex-column flex-root" id="kt_login_wrapper">
	<div class="login login-3 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" id="kt_login">
		<!--begin::Aside-->
		<div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
			style="background-color: #1A1A26;">
			<!--begin: Aside Container-->
			<div class="d-flex flex-row-fluid flex-column justify-content-between">
				<!--begin: Aside header-->
				<a href="#" class="flex-column-auto mt-5">
					<img src="./assets/media/logos/logo-aqui-pido.png" class="max-h-70px" />
				</a>
				<!--end: Aside header-->
				<!--begin: Aside content-->
				<div class="flex-column-fluid d-flex flex-column justify-content-center">
					<h3 class="font-size-h1 mb-5 text-white">Bienvenido a AquíPide</h3>
					<p class="font-weight-lighter text-white opacity-80">
						Administración general para la plataforma AquíPide.
					</p>
				</div>
				<!--end: Aside content-->
				<!--begin: Aside footer for desktop-->
				<div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
					<div class="opacity-70 font-weight-bold	text-white">
						&copy; 2021 AquiPide
					</div>
					<div class="d-flex">
						<a href="https://aquipide.com/Politica%20de%20privacidad%20AQUIPIDE.pdf" target="_blank" class="text-white">Privacidad</a>
						<a href="https://aquipide.com/Terminos%20y%20Condiciones%20AQUIPIDE.pdf" target="_blank" class="text-white ml-10">Terminós y condiciones</a>
						<a href="http://wa.me/+526681584547/?text=Me interesa mayor información sobre la AppWeb aquipide.com" class="text-white ml-10">Contacto</a>
					</div>
				</div>
				<!--end: Aside footer for desktop-->
			</div>
		</div>
		<!--begin::Aside-->
		<!--begin::Content-->
		<div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
			<!--begin::Content header-->
			<div
				class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
				<span class="font-weight-bold text-dark-50">
					<a href="https://aquipide.com/registro">  ¿No tienes una cuenta? Afíliate AQUI </a>
				</span>
				<!--<a href="javascript:;" routerLink="/auth/register" class="font-weight-bold ml-2"
					id="kt_login_signup">{{ "AUTH.GENERAL.SIGNUP_BUTTON" | translate }}</a>-->
			</div>
			<!--end::Content header-->
			<router-outlet></router-outlet>
			<!--begin::Content footer for mobile-->
			<div
				class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
				<div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
					&copy; 2021 Aqui Pide
				</div>
				<div class="d-flex order-1 order-sm-2 my-2">
					<a href="https://aquipide.com/Politica%20de%20privacidad%20AQUIPIDE.pdf" target="_blank" class="text-dark-75 text-hover-primary">Privacidad</a>
					<a href="https://aquipide.com/Terminos%20y%20Condiciones%20AQUIPIDE.pdf" target="_blank" class="text-white ml-10">Terminós y condiciones</a>
					<a href="http://wa.me/+526681584547/?text=Me interesa mayor información sobre la AppWeb aquipide.com" class="text-dark-75 text-hover-primary ml-4">Contacto</a>
				</div>
			</div>
			<!--end::Content footer for mobile-->
		</div>
		<!--end::Content-->
	</div>
</div>