// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  apiUrl: 'https://dev.aquipide.com/api/?u=',
  finalizarPedido: 'https://dev.aquipide.com/home/finalizar-pedido',
  uploadImg: 'https://dev.aquipide.com/api/',
  apiImg: 'https://dev.aquipide.com/api/img/',
  version: '1.1.3_betta_dev',
  firebaseConfig: {
    apiKey: "AIzaSyAkBaKrgjS6_a4WB9RsCQ0WWqsIzlyigXQ",
    authDomain: "aquipide-dev.firebaseapp.com",
    projectId: "aquipide-dev",
    storageBucket: "aquipide-dev.appspot.com",
    messagingSenderId: "14126796035",
    appId: "1:14126796035:web:e0e52bc93e54ebac10ed36",
    measurementId: "G-KVRJR8XJ9N"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
