import { Component, OnInit, Input, Output, EventEmitter, Pipe, ViewChild, ElementRef } from '@angular/core';
import { Tienda } from 'src/app/models/Tienda';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MyErrorStateMatcher } from '../../../utils/errorMatchers/ErrorStateMatcher';
import { AdmonGeneralService } from 'src/app/services/admon-general/admon-general.service';
import Swal from 'sweetalert2';
import { FileValidator } from 'ngx-material-file-input';
import { ImageService } from 'src/app/services/image.service';
import { environment } from 'src/environments/environment';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'kt-form-empresa',
  templateUrl: './form-empresa.component.html',
  styleUrls: ['./form-empresa.component.scss']
})
export class FormEmpresaComponent implements OnInit {
  apiImg = environment.apiImg;
  @Input() tienda: Tienda;
  @Input() editMode: boolean;
  @Output() returnEvent = new EventEmitter();
  readonly maxSize = 4194304; //4MB = 4 * 2 **20
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  formTienda: FormGroup;
  selectedEtiquetas;
  allEtiquetas = [];
  tagCtrl = new FormControl();
  filteredEtiquetas;
  datoRequerido: String = "Este dato es requerido";
  matcher = new MyErrorStateMatcher('logoTienda');
  loadingImage: boolean = false;
  listOfCategorias: any;
  listOfSubCategorias: any;
  paises: any;
  hide = true;
  girosComerciales: any;
  loading: boolean = false;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  constructor(private fb: FormBuilder, private dataService: AdmonGeneralService, private imageService: ImageService) {
    this.filteredEtiquetas = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag) => tag ? this._filter(tag) : this.allEtiquetas.slice()));
  }

  toggleImg() {
    let id = document.getElementsByTagName('ngx-mat-file-input').item(0).id;
    document.getElementById(id).click();
  }

  saveTienda(): void {
    this.loading = true;
    let tiendaToSave: Tienda = new Tienda();
    tiendaToSave.fillModel(this.formTienda.value);
    tiendaToSave.etiquetas = this.selectedEtiquetas;
    console.log(tiendaToSave);
    if (this.editMode == true) {
      this.updateTienda();
    } else {
      this.dataService.postRequest('createTienda', tiendaToSave).subscribe(data => {
        if (this.dataService.displayGenericSuccess(data)) {
          this.returnEvent.emit('Finalizado...');
        }
        this.loading = false;
      }, (error) => {
        this.dataService.displayGenericError(error);
        this.loading = false;
      })
    }
  }

  add(event): void {
    const input = event.input;
    const etiqueta = event.value;
    // Add our fruit
    if ((etiqueta || '').trim()) {
      this.selectedEtiquetas.push({ idEtiqueta: 0, nombreEtiqueta: etiqueta });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tagCtrl.setValue(null);
  }

  remove(etiqueta): void {
    if (etiqueta.idEtiqueta != 0)
      this.allEtiquetas.push(etiqueta);
    const index = this.selectedEtiquetas.findIndex(etiq => etiq.nombreEtiqueta == etiqueta.nombreEtiqueta);
    if (index >= 0) {
      this.selectedEtiquetas.splice(index, 1);
    }
  }

  selected(event) {
    let value = event.option.value;
    this.selectedEtiquetas.push(value);
    this.tagInput.nativeElement.value = '';
    this.allEtiquetas.splice(this.allEtiquetas.indexOf(value), 1);
    this.tagCtrl.setValue(null);
  }

  updateTienda(): void {
    this.loading = true;
    let tiendaToSave: Tienda = new Tienda();
    tiendaToSave.fillModel(this.formTienda.value);
    tiendaToSave.etiquetas = this.selectedEtiquetas;
    this.dataService.postRequest(`updateTienda`, tiendaToSave).subscribe(data => {
      this.dataService.displayGenericSuccess(data);
      this.loading = false;
      this.returnEvent.emit('Finalizado...');
    }, (error) => {
      this.dataService.displayGenericError(error);
      this.loading = false;
      //this.returnEvent.emit('Finalizado...');
    })
  }

  fileChangeEvent(file: any) {
    if (file != null && typeof file != undefined && file != '') {
      let imageToUpload: File = file.target.files[0];
      //SI LA IMAGEN SELECCIONADA NO TERMINA EN PNG O SI LA IMAGEN PESA MAS DE 4MB
      //SE SETEA LA IMAGEN COMO NULL PARA QUE NO PERMITA AVANZAR
      if (!imageToUpload.name.endsWith('png') || this.formTienda.controls['logoTienda'].hasError('maxContentSize') == true) {
        this.formTienda.controls['logoTienda'].setValue(null);
        return;
      }
      this.loadingImage = true;
      this.imageService.uploadImage(imageToUpload).subscribe(//Realiza la petición
        (data) => {//Si no hubo error se setea el nombre de la imagen al modelo 
          if (data["estatus"] == 200) {
            this.formTienda.controls['logoTienda'].setValue(data["nombreImagen"]);
            /*Swal.fire({
              title: 'Aviso',
              text: 'Imagen cargada con éxito',
              icon: 'success'
            })*/
          } else {
            this.dataService.displayGenericError(data);
          }
          this.loadingImage = false;
        },
        (error) => {
          this.formTienda.controls['logoTienda'].setValue(null);
          this.dataService.displayErrorByRequest(error);
          this.loadingImage = false;
        }
      );
    }
  }

  initForm() {
    if (this.tienda === undefined) {
      this.tienda = new Tienda();
      this.tienda.clearModel();
    }
    this.formTienda = this.fb.group({
      idTienda: [this.tienda.idTienda],
      idPais: [this.tienda.idPais, [Validators.required]],
      nombreComercial: [this.tienda.nombreComercial, [Validators.required, Validators.maxLength(100)]],
      rfc: [this.tienda.rfc, [Validators.required, Validators.maxLength(13)]],
      telefono: [this.tienda.telefono, [Validators.required, Validators.maxLength(10)]],
      correo: [this.tienda.correo, [Validators.required, Validators.email]],
      urlPersonalizada: [this.tienda.urlPersonalizada, [Validators.required]],
      nombreAdministrador: [this.tienda.nombreAdministrador, [Validators.required, Validators.maxLength(100)]],
      telefonoAdministrador: [this.tienda.telefonoAdministrador, [Validators.required, Validators.maxLength(10)]],
      correoAdministrador: [this.tienda.correoAdministrador, [Validators.required, Validators.email]],
      contrasena: [this.tienda.contrasena, [Validators.required, Validators.minLength(10)]],
      logoTienda: [this.tienda.logoTienda, [Validators.required]],
      pagoEfectivo: [this.tienda.pagoEfectivo, [Validators.required]],
      pagoTPV: [this.tienda.pagoTPV, [Validators.required]],
      giroComercial: [this.tienda.giroComercial, [Validators.required]],
      facebook: [this.tienda.facebook, [Validators.required, Validators.maxLength(50)]],
      twitter: [this.tienda.twitter, [Validators.required, Validators.maxLength(50)]],
      instagram: [this.tienda.instagram, [Validators.required, Validators.maxLength(50)]],
      estatus: [this.tienda.estatus, [Validators.required]],
    });
    this.selectedEtiquetas = this.tienda.etiquetas;
    this.formTienda.addControl('logoInput', this.fb.control(null));
    this.formTienda.addControl('terminos', this.fb.control(false));
    this.formTienda.removeControl('logoInput');
    this.formTienda.addControl('logoInput', this.fb.control(null));
    this.formTienda.controls['logoInput'].setValidators([FileValidator.maxContentSize(this.maxSize)]);
  }

  getPaises() {
    this.dataService.getRequest(`getPaises`).toPromise().then(data => {
      this.paises = data["data"];
    }, error => {
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error al recuperar los países. ' + error.message,
        icon: 'error'
      })
    });
  }

  getGirosComerciales() {
    this.dataService.getRequest(`getGirosComerciales`).toPromise().then(data => {
      this.girosComerciales = data["data"];
    }, error => {
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error al recuperar los giros comerciales. ' + error.message,
        icon: 'error'
      })
    });
  }

  getEtiquetas() {
    this.dataService.getRequest('getEtiquetas').toPromise().then((etiqs: any) => {
      this.allEtiquetas = etiqs.data;
      this.selectedEtiquetas.forEach(element => {
        const index = this.allEtiquetas.findIndex((tag) => tag.idEtiqueta == element.idEtiqueta);
        if (index >= 0) {
          this.allEtiquetas.splice(index, 1);
        }
      });
    }).catch(error => {
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error al recuperar las etiquetas. ' + error.message,
        icon: 'error'
      })
    });
  }

  private _filter(value: any) {
    if (typeof value == 'string') {
      const filterValue = value.toLowerCase();
      return this.allEtiquetas.filter(etiqueta => {
        return etiqueta.nombreEtiqueta.toLowerCase().indexOf(filterValue) >= 0;
      });
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.getPaises();
    this.getEtiquetas();
    this.getGirosComerciales();
  }

}
