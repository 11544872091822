// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { AuthService } from 'src/app/core/auth';
@Component({
  selector: 'kt-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  // Public properties
  user$: Observable<any>;
  @Input() userDropdownStyle = 'light';
  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;
  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   */
  constructor(private authService: AuthService) {
  }
  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */
  /**
   * On init
   */
  ngOnInit(): void {
    this.user$ = this.authService.getCurrentUser();
  }
  /**
   * Log out
   */
  logout() {
    //Cerrar sesión
  }
}
