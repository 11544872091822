import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../_services';

@Injectable()
export class AuthAdminTiendaGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLogged = this.authService.isLoggedIn();
    const rol = this.authService.getCurrentUser()["rol"];
    if (isLogged == false || rol != 'Administrador Tienda') {
      this.router.navigateByUrl('/auth/login');
      return false;
    } else {
      return true;
    }
  }
}

  