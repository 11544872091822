import { FormBuilder, FormGroup, Validators, FormControl, NgForm, FormGroupDirective } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
export class MyErrorStateMatcher implements ErrorStateMatcher {
    controlName: string;
    constructor(controlName: string) {
        this.controlName = controlName;
    }
    readonly maxSize = 4194304; //4MB = 4 * 2 **20
    public isErrorState(control: FormControl, _: NgForm | FormGroupDirective): boolean {
        const size = control?.value?._files[0]?.size > this.maxSize;
        const extValidation = !(control?.value?._fileNames?.endsWith('png'));
        if (_.form.controls[this.controlName]?.value == null) { //Sí el logo no tiene cargado alguna imagen
            if (extValidation == true) {
                if (control.errors?.maxContentSize) {
                    let maxContentSize = control.errors.maxContentSize;
                    control.setErrors({ maxContentSize, extension: extValidation });
                } else {
                    control.setErrors({ extension: extValidation });
                }
            } else {
                control.updateValueAndValidity()
            }
        } else { //Sí el logo tiene previamente cargado alguna imagen se le quitan los errores
            control.setErrors(undefined);
        }
        return (control && control.value && control.value._fileNames && (size || extValidation));
    }
}