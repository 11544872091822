import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/core/auth';
@Injectable({
  providedIn: 'root'
})
export class AdmonGeneralService {
  constructor(private http: HttpClient, private authService: AuthService) {
  }
  getRequest(url: string) {
    return this.http.post(`${environment.apiUrl}${url}`, {}, { headers: new HttpHeaders().append('Authorization', this.authService.currentToken) });
  }
  postRequest(url: string, body: any, withAuth = true) {
    if(withAuth)
      return this.http.post(`${environment.apiUrl}${url}`, body, { headers: new HttpHeaders().append('Authorization', this.authService.currentToken) });
    else
      return this.http.post(`${environment.apiUrl}${url}`, body);
  }
  putRequest(url: string, body: any) {
    return this.http.put(`${environment.apiUrl}${url}`, body, { headers: new HttpHeaders().append('Authorization', this.authService.currentToken) });
  }
  // deleteRequest(url: any) {
  //   return this.http.delete(`${environment.apiUrl}`, /*{ headers: new HttpHeaders().append('Authorization', this.token) }*/);
  // }
  // getRequest(url: string) {
  //   return this.http.get(`${environment.apiUrl}${url}`, /*{ headers: new HttpHeaders().append('Authorization', this.token) }*/);
  // }
  // postRequest(url: string, body: any) {
  //   return this.http.post(`${environment.apiUrl}${url}`, body, /*{ headers: new HttpHeaders().append('Authorization', this.token) }*/);
  // }
  // putRequest(url: string, body: any) {
  //   return this.http.put(`${environment.apiUrl}${url}`, body, /*{ headers: new HttpHeaders().append('Authorization', this.token) }*/);
  // }
  // deleteRequest(url: any) {
  //   return this.http.delete(`${environment.apiUrl}`, /*{ headers: new HttpHeaders().append('Authorization', this.token) }*/);
  // }
  displayGenericError(error: any) {
    
    if (error['status'] === 401) {
      Swal.fire({
        title: "Sin autorización, inicie sesión nuevamente",
        icon: "error"
      })
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error. ' + error.message,
        icon: 'error'
      })
    }
  }
  validateRedFields(){
    Swal.fire({
      text: 'Por favor completa los campos marcados con rojo',
      icon: "info"
    })
  }
  displayGenericSuccess(data: any) {
    if (data["estatus"] == 200) {
      Swal.fire({
        title: data["mensaje"],
        icon: "success"
      })
      return true;
    } else {
      Swal.fire({
        title: data["mensaje"],
        icon: "info"
      })
      return false;
    }
  }
  displayErrorByRequest(error) {
    let errorText: String = error["error"]["text"];
    if (errorText != null) {//SI EL ERROR ES ARROJADO POR LA API
      // errorText = errorText.substring(errorText.indexOf("{") - 1, errorText.indexOf("}") + 1);
      // 
      // let errorJson = JSON.parse(errorText.toString());
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error. ' + errorText,
        icon: 'error'
      })
    } else { //SI EL ERROR NO ES ARROJADO POR LA API
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error. ' + error["error"]["message"],
        icon: 'error'
      })
    }
  }
  displayInfoMessage(message: string) {
    Swal.fire({
      title: 'Info',
      text: message,
      icon: 'info'
    })
  }
}