import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdmonGeneralService } from 'src/app/services/admon-general/admon-general.service';

@Component({
  selector: 'kt-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss']
})
export class VersionInfoComponent implements OnInit {
  @Input() showOnlyLastChanges: boolean;
  versionChangesList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  constructor(private generalService: AdmonGeneralService) {
    this.showOnlyLastChanges = this.showOnlyLastChanges ? true : false;
  }

  getVersions() {
    this.generalService.getRequest(this.showOnlyLastChanges ? 'getLastReleaseAdminPanel' : 'getReleasesAdminPanel').toPromise().then(data => {
      for (let i = 0; i < data["data"].length; i++) {
        const element = data["data"][i];
        data["data"][i].list = data["data"][i].data.split('|||');
      }
      this.versionChangesList.next(data["data"]);
    });
  }

  ngOnInit(): void {
    this.getVersions();
  }

}
