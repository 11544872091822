import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(private http: HttpClient) { }
  uploadImage(image: File) {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post(`${environment.uploadImg}/subirImagen.php`, formData, /*{ headers: new HttpHeaders().append('Authorization', this.token) }*/);
  }
}
