import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from "rxjs/operators";
import { LoadingService } from '../services/loading.service';
@Injectable({
  providedIn: 'root'
})
export class LoadingInterceptor implements HttpInterceptor {
  totalRequests = 0;
  constructor(public loadingService: LoadingService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*if (this.totalRequests === 0) {
      this.loadingService.show();
    }
    this.totalRequests++;
    return next.handle(req).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.loadingService.hide();
        }
      })
    );*/
    this.loadingService.show();
    this.totalRequests++;
    return next.handle(req).pipe(tap(
      event => {},//console.log(event),
      error => {}//console.log(error),
    ), finalize(() => {
      this.totalRequests--;
      if (this.totalRequests == 0) {
        this.loadingService.hide();
      }
    })
    )
  }
}
