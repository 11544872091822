<mat-card>
    <mat-card-header>
        <mat-card-title>{{editMode==true?'Editar Empresa':'Agregar Empresa'}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="formTienda" (ngSubmit)="saveTienda()">
            <div class="row" style="margin: 0 16px;">
                <!--PARTE LATERAL IZQUIERDA-->
                <div class="col-sm-12 col-lg-6" style="padding:0 !important;">
                    <br>
                    <div class="row">
                        <div class="col-sm-12">
                            <h4>Datos generales de la empresa</h4>
                            <mat-form-field class="col-sm-12">
                                <mat-icon matPrefix class="fas fa-flag">&nbsp;&nbsp;</mat-icon>
                                <mat-label>País</mat-label>
                                <mat-select formControlName="idPais" [disabled]="editMode==true">
                                    <mat-option>Selecciona el país</mat-option>
                                    <mat-option *ngFor="let pais of paises" [value]="pais?.idPais">
                                        {{pais?.claveInternacional}} - {{pais?.nombre}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formTienda.get('idPais').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-sm-12">
                                <mat-icon matPrefix class="fas fa-briefcase">&nbsp;&nbsp;</mat-icon>
                                <mat-label>Giro comercial</mat-label>
                                <mat-select formControlName="giroComercial">
                                    <mat-option>Selecciona el giro comercial</mat-option>
                                    <mat-option *ngFor="let giro of girosComerciales" [value]="giro?.idGiroComercial">
                                        {{giro?.descripcion}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formTienda.get('giroComercial').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-sm-12">
                                <mat-icon matPrefix class="fas fa-building">&nbsp;&nbsp;</mat-icon>
                                <input maxlength="100" matInput placeholder="Nombre" formControlName="nombreComercial"
                                    required>
                                <mat-error *ngIf="formTienda.get('nombreComercial').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('nombreComercial').errors?.maxlength">
                                    Máximo número de caracteres
                                    {{formTienda.get('nombreComercial').errors?.maxlength.actualLength}}/{{formTienda.get('nombreComercial').errors?.maxlength.requiredLength}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-sm-12">
                                <mat-icon matPrefix class="fas fa-registered">&nbsp;&nbsp;</mat-icon>
                                <input maxlength="13" matInput placeholder="R F C" formControlName="rfc" required>
                                <mat-error *ngIf="formTienda.get('rfc').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('rfc').errors?.maxlength">
                                    Máximo número de caracteres
                                    {{formTienda.get('rfc').errors?.maxlength.actualLength}}/{{formTienda.get('rfc').errors?.maxlength.requiredLength}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-sm-12">
                                <mat-icon matPrefix class="fas fa-phone">&nbsp;&nbsp;</mat-icon>
                                <input maxlength="10" type="text" matInput placeholder="Teléfono"
                                    formControlName="telefono" required>
                                <mat-error *ngIf="formTienda.get('telefono').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('telefono').errors?.maxlength">
                                    Máximo número de caracteres
                                    {{formTienda.get('telefono').errors?.maxlength.actualLength}}/{{formTienda.get('telefono').errors?.maxlength.requiredLength}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-sm-12">
                                <mat-icon matPrefix class="fas fa-envelope">&nbsp;&nbsp;</mat-icon>
                                <input type="email" matInput placeholder="Correo" formControlName="correo" required>
                                <mat-error *ngIf="formTienda.get('correo').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('correo').errors?.email">
                                    Introduzca un correo válido
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-sm-12">
                                <mat-icon matPrefix class="fas fa-external-link-alt">&nbsp;&nbsp;</mat-icon>
                                <input type="text" matInput placeholder="Url Personalizada"
                                    formControlName="urlPersonalizada" required>
                                <mat-error *ngIf="formTienda.get('urlPersonalizada').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                            </mat-form-field>
                            <span class="col-sm-12 col-md-6">
                                <mat-label>Estatus </mat-label>
                                <mat-slide-toggle formControlName="estatus">
                                </mat-slide-toggle>
                            </span>
                            <br><br><br>
                            <h4>Datos del administrador</h4><br>
                            <mat-form-field class="col-sm-12">
                                <mat-icon matPrefix class="fas fa-user-tie">&nbsp;&nbsp;</mat-icon>
                                <input maxlength="100" matInput placeholder="Nombre administrador"
                                    formControlName="nombreAdministrador" required>
                                <mat-error *ngIf="formTienda.get('nombreAdministrador').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('nombreAdministrador').errors?.maxlength">
                                    Máximo número de caracteres
                                    {{formTienda.get('nombreAdministrador').errors?.maxlength.actualLength}}/{{formTienda.get('nombreAdministrador').errors?.maxlength.requiredLength}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-sm-12">
                                <mat-icon matPrefix class="fas fa-phone">&nbsp;&nbsp;</mat-icon>
                                <input maxlength="10" matInput type="text" placeholder="Teléfono administrador"
                                    formControlName="telefonoAdministrador" required>
                                <mat-error *ngIf="formTienda.get('telefonoAdministrador').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('telefonoAdministrador').errors?.maxlength">
                                    Máximo número de caracteres
                                    {{formTienda.get('telefonoAdministrador').errors?.maxlength.actualLength}}/{{formTienda.get('telefonoAdministrador').errors?.maxlength.requiredLength}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-sm-12">
                                <mat-icon matPrefix class="fas fa-envelope">&nbsp;&nbsp;</mat-icon>
                                <input matInput type="email" placeholder="Correo administrador"
                                    formControlName="correoAdministrador" required>
                                <mat-error *ngIf="formTienda.get('correoAdministrador').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('correoAdministrador').errors?.email">
                                    Introduzca un correo válido
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-sm-12">
                                <span mat-icon-button matPrefix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon matPrefix class="{{hide? 'fas fa-eye' : 'fas fa-eye-slash'}}">&nbsp;&nbsp;
                                    </mat-icon>
                                </span>
                                <input type="password" matInput placeholder="Contraseña" formControlName="contrasena"
                                    [type]="hide ? 'password' : 'text'" required>
                                <mat-error *ngIf="formTienda.get('contrasena').errors?.required">
                                    {{datoRequerido}}
                                </mat-error>
                                <mat-error *ngIf="formTienda.get('contrasena').errors?.minlength">
                                    La contraseña debe tener mínimo
                                    {{formTienda.get('contrasena').errors?.minlength.requiredLength}}
                                    caracteres </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <!--FIN PARTE LATERAL IZQUIERDA-->
                <!--PARTE LATERAL DERECHA-->
                <div class="col-sm-12 col-lg-6" style="padding:0 !important;">
                    <div class="col-sm-12">
                        <div class="row alignItemsCenter">
                            <img (click)="toggleImg()" *ngIf="loadingImage==false" class="circleImg cursor-pointer"
                                src="{{apiImg}}{{formTienda.controls['logoTienda'].value}}"
                                onError="this.src='assets/img/noimage.png'">
                            <img (click)="toggleImg()" *ngIf="loadingImage==true" class="circleImg cursor-pointer"
                                src="assets/img/loading.gif"> </div>
                        <div class="row alignItemsCenter">
                            <div class="col">
                                <mat-form-field>
                                    <mat-icon matSuffix *ngIf="removableInput.empty" class="fas fa-image">
                                    </mat-icon>
                                    <mat-icon matSuffix *ngIf="!removableInput.empty" class="fas fa-close"
                                        (click)="removableInput.clear($event);formTienda.controls['logoTienda'].setValue('')">
                                    </mat-icon>
                                    <ngx-mat-file-input #removableInput formControlName="logoInput"
                                        placeholder="Imagen del Tienda" [accept]="'.png'"
                                        (change)="fileChangeEvent($event)" [errorStateMatcher]="matcher">
                                    </ngx-mat-file-input>
                                    <mat-error *ngIf="formTienda?.controls?.logoInput?.errors?.extension">
                                        Por favor, selecciona la imagen del Tienda (Sólo imagenes con
                                        extensión .png)
                                    </mat-error>
                                    <mat-error *ngIf="formTienda?.controls?.logoInput?.errors?.maxContentSize">
                                        El tamaño de la imagen no debe de superar los
                                        {{formTienda?.controls?.logoInput?.errors?.maxContentSize?.maxSize | byteFormat}}
                                        ({{formTienda?.controls?.logoInput?.errors?.maxContentSize?.actualSize | byteFormat}}).
                                    </mat-error>
                                    <mat-error *ngIf="formTienda?.controls?.logoTienda?.errors?.required">
                                        {{datoRequerido}}
                                    </mat-error>
                                </mat-form-field><br>
                                <div style="margin-top: -20px;">
                                    <span style="color: red; font-size: small; font-weight: 400;"> * Formato PNG,
                                        1MB.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4>Etiquetas </h4>
                    <mat-form-field class="col-12">
                        <mat-chip-list #chipList>
                            <mat-chip *ngFor="let etiqueta of selectedEtiquetas" (removed)="remove(etiqueta)">
                                {{etiqueta.nombreEtiqueta}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input #tagInput placeholder="Nueva etiqueta..." [matChipInputFor]="chipList"
                                [formControl]="tagCtrl" [matAutocomplete]="auto"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            (optionSelected)="selected($event)">
                            <mat-option *ngFor="let tag of filteredEtiquetas | async" [value]="tag">
                                {{tag.nombreEtiqueta}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <br><br><br>
                    <h4>Métodos de pago</h4>
                    <span class="col-sm-12 col-md-6">
                        <mat-label>Pago en efectivo</mat-label>
                        <mat-slide-toggle formControlName="pagoEfectivo">
                        </mat-slide-toggle>
                    </span><br><br>
                    <span class="col-sm-12 col-md-6">
                        <mat-label>Pago TPV</mat-label>
                        <mat-slide-toggle formControlName="pagoTPV">
                        </mat-slide-toggle>
                    </span>
                    <br><br><br>
                    <h4>Redes sociales</h4>
                    <mat-form-field class="col-sm-12">
                        <mat-icon matPrefix class="fab fa-facebook">&nbsp;&nbsp;</mat-icon>
                        <input maxlength="50" matInput placeholder="Facebook" formControlName="facebook">
                        <mat-error *ngIf="formTienda.get('facebook').errors?.maxlength">
                            Máximo número de caracteres
                            {{formTienda.get('facebook').errors?.maxlength.actualLength}}/{{formTienda.get('facebook').errors?.maxlength.requiredLength}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-sm-12">
                        <mat-icon matPrefix class="fab fa-twitter">&nbsp;&nbsp;</mat-icon>
                        <input maxlength="50" matInput placeholder="Twitter" formControlName="twitter">
                        <mat-error *ngIf="formTienda.get('twitter').errors?.maxlength">
                            Máximo número de caracteres
                            {{formTienda.get('twitter').errors?.maxlength.actualLength}}/{{formTienda.get('twitter').errors?.maxlength.requiredLength}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-sm-12">
                        <mat-icon matPrefix class="fab fa-instagram">&nbsp;&nbsp;</mat-icon>
                        <input maxlength="50" matInput placeholder="Instagram" formControlName="instagram">
                        <mat-error *ngIf="formTienda.get('instagram').errors?.maxlength">
                            Máximo número de caracteres
                            {{formTienda.get('instagram').errors?.maxlength.actualLength}}/{{formTienda.get('instagram').errors?.maxlength.requiredLength}}
                        </mat-error>
                    </mat-form-field>
                    <p>
                        Al usar la aplicación AQUI PIDE he leido el <a
                            href="https://aquipide.com/Politica%20de%20privacidad%20AQUIPIDE.pdf" target="_blank"
                            rel="noopener noreferrer">acuerdo de privacidad</a> y acepto los <a
                            href="https://aquipide.com/Terminos%20y%20Condiciones%20AQUIPIDE.pdf" target="_blank"
                            rel="noopener noreferrer">terminos y condiciones</a>.
                    </p>
                    <span class="col-sm-12 col-md-6">
                        <mat-checkbox formControlName="terminos">Acepto los términos y condiciones</mat-checkbox>
                    </span>
                    <div class="container">
                        <div class="row justify-content-center">
                            <button [class.spinner]="loading"
                                [disabled]="formTienda.invalid || formTienda.value.terminos==false || loading"
                                class="col-sm-4" mat-raised-button color="primary" type="submit">
                                <i class="fas fa-save"></i>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
                <!--FIN PARTE LATERAL DERECHA-->
            </div>
        </form>
    </mat-card-content>
</mat-card>