<!-- begin:: Error -->
<div class="error error-4 d-flex flex-row-fluid bgi-size-cover bgi-position-center" style="background-image: url(./assets/media/error/bg4.jpg); height: 100%;">
  <!--begin::Content-->
  <div class="d-flex flex-column flex-row-fluid align-items-center align-items-md-start justify-content-md-center text-center text-md-left px-10 px-md-30 py-10 py-md-0 line-height-xs">
    <h1 class="error-title text-success font-weight-boldest line-height-sm">
      404
    </h1>
    <p class="error-subtitle text-success font-weight-boldest mb-10">
      ERROR
    </p>
    <p class="display-4 text-danger font-weight-boldest mt-md-0 line-height-md">
      Nada para mostrar aquí.
    </p>
  </div>
  <!--end::Content-->
</div>
<!-- end:: Error -->
