// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService } from '../../../../core/auth';
import Swal from 'sweetalert2';
import { AdmonGeneralService } from 'src/app/services/admon-general/admon-general.service';
import { environment } from 'src/environments/environment';
/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	EMAIL: '',
	PASSWORD: ''
};
@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	private unsubscribe: Subject<any>;
	private returnUrl: any = 'administracion-general/dashboard';
	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private dataService: AdmonGeneralService,
		private authService: AuthService,
	) {
		this.unsubscribe = new Subject();
	}
	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */
	/**
	 * On init
	 */
	ngOnInit(): void {
		this.initLoginForm();
		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params.returnUrl || '/';
		});
	}
	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}
	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		// demo message to show
		if (!this.authNoticeService.onNoticeChanged$.getValue()) {
			const initialNotice = `Use account
			<strong>${DEMO_PARAMS.EMAIL}</strong> and password
			<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
			this.authNoticeService.setNotice(initialNotice, 'info');
		}
		this.loginForm = this.fb.group({
			email: [DEMO_PARAMS.EMAIL, Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: [DEMO_PARAMS.PASSWORD, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});
	}
	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loading = true;
		const authData = {
			correo: controls.email.value,
			contrasena: controls.password.value
		};
		this.auth
			.login(authData.correo, authData.contrasena)
			.pipe(
				tap(async user => {
					if (user["estatus"] == 200) {
						localStorage.setItem(environment.authTokenKey, user["data"]);
						await this.dataService.postRequest('getTienda', {}).toPromise().then((resp: any) => {
							if (resp.estatus == 200) {
								this.auth.tienda = resp.data;
								localStorage.setItem('tiendaActual', JSON.stringify(resp.data));
							}
						});
						let infoUser = this.authService.getCurrentUser();
						this.returnUrl = (infoUser.rol == 'Administrador General'
							? 'administracion-general/dashboard'
							: infoUser.rol == 'Administrador Tienda'
								? 'administracion-tienda/dashboard'
								: infoUser.rol == 'Administrador de Sucursal' ? 'administracion-sucursal/pedidos' : '');

						this.router.navigateByUrl(this.returnUrl); // Main page
					} else {
						Swal.fire({
							title: 'Error',
							text: 'Ocurrió un error. ' + user["mensaje"],
							icon: 'error'
						});
						this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
					}
				}, error => {
					this.dataService.displayErrorByRequest(error);
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
			.subscribe();
	}
	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
