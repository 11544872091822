import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
@NgModule({
    imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule],
    declarations: [ModalComponent],
    exports: [ModalComponent]
})
export class ModalModule { }